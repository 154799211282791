import React from "react";
import Minesweeper from "../../Games/Minesweeper/minesweeper";
import { createPortal } from "react-dom";

interface IGameScreenProps {
  showingGame: boolean;
  toggleGame: () => void;
}

const GameScreen = ({ showingGame, toggleGame }: IGameScreenProps) => {
  if (!showingGame) return <React.Fragment></React.Fragment>;
  return (
    <div>
      {createPortal(
        <div
          style={{
            position: "fixed",
            zIndex: 99999,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0,0,0,0.93)",
          }}
        >
          <div style={{ width: "40%", margin: "50px auto 0 auto" }}>
            <button
              style={{
                cursor: "pointer",
                float: "right",
                zIndex: 900,
                backgroundColor: "transparent",
                border: "2px solid gray",
                color: "white",
                fontSize: "2em",
                height: "100px",
                width: "100px",
              }}
              onClick={toggleGame}
            >
              X
            </button>
            <Minesweeper />
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default GameScreen;
