import { useState } from "react";

import styles from "./About.module.css";

import SectionHeading from "../../UI/UIComponents/SectionHeading";
import MyHistory from "./AboutComponents/HistoryComponents/MyHistory";
import GameScreen from "../Landing/GameScreen";

const About = () => {
  const [showingGame, setShowingGame] = useState(false);

  const toggleGame = () => {
    setShowingGame((prevValue) => !prevValue);
  };

  return (
    <div id="about" className="blue-section">
      <SectionHeading offWhite={false}>About</SectionHeading>
      <div
        style={{
          margin: "0 auto",
          width: "fit-content",
        }}
      >
        <button className={styles.btnSlide} onClick={toggleGame}>
          ?
        </button>
      </div>
      <GameScreen showingGame={showingGame} toggleGame={toggleGame} />
      <div className={styles.aboutContainer}>
        <MyHistory />
      </div>
    </div>
  );
};

export default About;
